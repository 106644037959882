import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';
import { searchUpdate, clear, updateTerm } from '../modules/searchdata';
import { alpha } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

let timerHandle = null;

const useStyles = makeStyles((theme) => ({
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(1),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(2),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 1),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cancelIcon: {
        padding: theme.spacing(0, 1),
        height: '100%',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: 0,
        left: 'calc(100% - 40px);',
        cursor: 'pointer',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
}));

export default function SearchField(props) {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const term = useSelector((state) => state.searchdata.searchTerm);

    const goToSearchResultsPage = useCallback(() => {
        if (props.history.location.pathname.toLowerCase().search('search') !== -1) {
            props.history.replace('/search/' + encodeURIComponent(term));
        } else {
            props.history.push('/search/' + encodeURIComponent(term));
        }
    }, [props.history, term]);

    const goToPreviousPage = useCallback(() => {
        if (props.history.location.pathname.toLowerCase().search('search') !== -1) {
            props.history.goBack();
        }
    }, [props.history]);

    useEffect(() => {
        if (term.length > 3) {
            timerHandle = setTimeout(() => {
                goToSearchResultsPage();
                dispatch(searchUpdate(term));
            }, 1000);
        }
        if (term.length === 0) {
            goToPreviousPage();
            dispatch(clear());
        }

        return () => {
            if (timerHandle) {
                clearTimeout(timerHandle);
                timerHandle = null;
            }
        };
    }, [term, dispatch, goToPreviousPage, goToSearchResultsPage]);

    const setTerm = (newTerm) => {
        dispatch(updateTerm(newTerm));
    };

    const onChange = (evt) => {
        setTerm(evt.target.value);
    };

    const onCancel = () => {
        setTerm('');
    };

    return (
        <div className={classes.search}>
            <div className={classes.searchIcon}>
                <SearchIcon />
            </div>

            <InputBase
                placeholder={t('searchHint')}
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                }}
                name="global-search-input"
                value={term}
                inputProps={{ 'aria-label': 'search' }}
                onChange={onChange}
            />
            {term.length > 0 && (
                <div className={classes.cancelIcon}>
                    <CancelIcon name="btn-cancel" onClick={onCancel} />
                </div>
            )}
        </div>
    );
}
